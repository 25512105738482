function HowToBuy() {
    return (
        <section id="how-to-buy">
            <div className="row justify-content-center">

                <label className="text-center subtitle-1 d-sm-none mb-4 mb-sm-0">
                    How to Buy & Join?
                </label>

                <div className="col-12">

                    <div className="container-tr-2">

                        <div className="row">

                            <div className="col-12 col-md-6">
                                <label className="subtitle-2">
                                    Step by Step:
                                </label>

                                <p>
                                    1) To purchase NFT's from our collection you will need to pay using Ethereum.
                                    <br /><br />
                                    2) You will need to open a Cryptocurrency Wallet to carry Ethereum and pay for the NFT. We use <a href="https://metamask.io/" target="_blank">MetaMask</a>.
                                    <br /><br />
                                    3) That Wallet will also allow you to hold (own) your NFT.
                                    <br /><br />
                                    4) Once you have funds in your Wallet, you can connect wallet to our website by clicking the "Mint a
                                    Fergus!" button and purchase your NFT item
                                    <br /><br />
                                    5) Our NFT Collection in an NFT Marketplace called OpenSea.io. If you ever decide to resell it you
                                    can do so there.
                                </p>

                            </div>

                            <div className="col-12 col-md-6">

                                <label className="subtitle-2">
                                    Distribution Roadmap
                                </label>
                                <ul>
                                    <li className="mb-3">Original Edition: 9 Items. Held for TheGrint team members.</li>
                                    <li className="mb-3">First Edition: 90 Items. Promoted privately to most active Grinters, minted in our
                                        website. </li>
                                    <li className="mb-3">Second Edition: 900 Items openly available to all Grinters and OpenSea community.
                                        Minted in our website</li>
                                </ul>

                                <div className="text-center mt-5">
                                    <img src="assets/images/circle-fergus.png" alt="Fergus" />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="text-center mt-5 mx-auto">
                    <a href="https://metamask.io/" className="btn btn-c-3" target="_blank">
                        Open Your Meta Mask
                    </a>
                    <div className="d-sm-none m-3"></div>
                    <div className="d-none d-md-inline-block mx-2"></div>
                    <a href="/mint-fergus" className="btn btn-c-4 ml-md-2">
                        Mint a Fergus
                    </a>
                </div>

            </div>
        </section>
    );
}

export default HowToBuy;