import MisteryFergus from './MisteryFergus';
import { ProgressBar } from './ProgressBar';
//STEP #1
export function Form(props) {
    let buttonMessageMint = (props.totalToMint > 1) ? `Mint ${props.totalToMint}x Fergus` : 'Mint a Fergus'
    return (
        <div className="row justify-content-center align-items-center">

            <div className="col-12">

                <div className="container-tr-2">
                    <div>
                        Now Minting:
                        <ProgressBar currentTotalSupply={props.currentTotalSupply} />
                    </div>

                    <div className="row">
                        <div className="col text-center">
                            <label className="d-block mt-5 mb-4">
                                ETH 0.3 / Fergus
                            </label>
                            <div className='text-center'>
                                <MisteryFergus totalToMint={props.totalToMint} />
                            </div>

                            <div className='form-mint my-5 d-flex justify-content-center'>
                                <button className="btn btn-total mx-2" onClick={props.handleTotalMint(-1)} disabled={!(props.totalToMint > 1)}>
                                    -
                                </button>
                                <input type="text" className='show-total mx-2' value={props.totalToMint} readOnly />
                                <button className="btn btn-total mx-2" onClick={props.handleTotalMint(1)} disabled={!(props.totalToMint < props.maxTotalMint)}>
                                    +
                                </button>
                            </div>

                        </div>
                    </div>

                </div>

                <div className='text-center mt-4'>
                    <input className="form-check-input" type="checkbox" value="" id="checkAcceptTerms" onClick={props.handleAcceptTerms} />
                    &nbsp;
                    <span className="form-check-label" htmlFor="checkAcceptTerms">
                        I accept the <a href='/terms-conditions'>Terms & Conditions</a>
                    </span>
                    <button className="btn btn-c-2 mt-3 d-block m-auto" disabled={!props.statusMintButton} onClick={() => props.mintFergus()}>
                        {buttonMessageMint}
                    </button>
                </div>


            </div>
        </div >
    )
}


//STEP #2 (Loading)
export function Loading(props) {
    return (
        <div className="row justify-content-center mt-5">
            <label className="text-center subtitle-3 mb-4">
                Minting {props.totalToMint}x Fergus Now
            </label>
            <div className="col-12 text-center">
                <div className="row justify-content-center gx-5">
                    <div className="col-6 col-md-3">
                        <div className="minting p-5">
                            <img className="img-fluid" src="assets/images/minting.gif" alt="Minting" />
                        </div>
                    </div>
                </div>
                <p className="mt-4">
                    This make take a few seconds...
                </p>
            </div>
        </div>
    );
}


//STEP #3
export function Success(props) {

    const MintedFergusImages = props.tokensIds.map((id) =>
        <div className="col-4 col-sm-3 mt-2" key={id} >
            <div className="text-center">
                <img className="img-fluid nft-minted" src={'assets/images/minted-nfts/' + id + '.png'} alt="fergus" />
            </div>
        </div>
    );

    return (
        <div className="row justify-content-center mt-5">
            <label className="text-center subtitle-3 mb-4">
                SUCCESS!
            </label>
            <div className="col-12 text-center">
                <div className="row justify-content-center gx-3 gx-sm-5">
                    {MintedFergusImages}
                </div>
                <p className="mt-5 fs-4">
                    <b>You have successfully acquired a Fergus NFT!</b>
                </p>
                <p className='mt-4'>
                    This item has been assigned to your MetaMask wallet.
                    <br />
                    You will also be able to find your item on openseas.io
                </p>
                <br />
                <div className="text-center mt-3 mx-auto">
                    <a href="https://opensea.io/collection/thegrint" className="btn btn-c-3" target="_blank">Go to Openseas</a>
                    <div className="d-sm-none m-3">
                    </div>
                    <div className="d-none d-md-inline-block mx-2"></div>
                    <a href="https://discord.gg/kRYqN2w7HZ" target="_blank" className="btn btn-c-4 ml-md-2">Join our Discord</a>
                </div>                
            </div>
        </div>
    );
}



//STEP #3
export function Error(props) {

    return (
        <div className="row justify-content-center mt-5">
            <label className="text-center subtitle-3 mb-4">
                Oops! Something went wrong...
            </label>
            <div className="col-12 col-sm-8 text-center">
                <div className="row justify-content-center">
                    <div className="col-8 col-sm-4">
                        <div className="text-center">
                            <img className="img-fluid nft-minted" src={'assets/images/failed.png'} alt="fergus" />
                        </div>
                    </div>
                </div>

                <p className="mt-5 fs-4">
                    <b>Your NFT might be in Opensea! Please check!</b>
                </p>
                <p className='mt-4 text-center'>
                    Please tap the button below to be redirected to opensea. Verify that you have received your NFT
                    and if you didn't , please try again!
                </p>
                <br />

                <div className="text-center mt-3 mx-auto">
                    <a href="https://opensea.io/collection/thegrint" className="btn btn-c-3" target="_blank">Go to Openseas</a>
                </div>
                
                <br />

                <div className='mt-3'>
                    Have any further questions? <a href='/contact-us' className='text-decoration-underline text-white'>Contact Us</a>
                </div>

            </div>
        </div>
    );
}

