function LockerRoom() {
    return (
        <section id="locker-room">
            <div className="row justify-content-center align-items-center">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4">

                    <label className="text-center subtitle-1 d-sm-none mb-4 mb-sm-0">
                        The Locker Room
                    </label>

                    <div className="row justify-content-center">
                        <div className="col-10 col-sm-10 col-xxl-10">
                            <div className="nft-card p-3 bg-yellow text-center">
                                <img className="img-fluid" src="assets/images/nfts/fergus-p3-mixed.png" alt="fergus" />
                            </div>
                            <p>
                                <label className="subtitle-3">
                                    Initial Funds
                                </label>
                                Funds from the sale of our NFT collection will be used to build the first version of the LockerRoom.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-7">
                    <div className="container-tr-1">
                        <label className="subtitle-2">
                            TheGrint Metaverse!
                        </label>

                        <p>
                            As we evolve TheGrint from your phone and computer to the virtual world (i.e. The Metaverse), The
                            LockerRoom will be the first version of that.
                            <br /><br />
                            Each Grinter will have their own locker, which is a place where you will be able to see your TheGrint
                            Stats, Re-Live Rounds, store Trophies from TheGrint Tour wins, and more.
                            <br /><br />
                            Additionally, those lockers will be located in clubhouses where you will be able to interact with other
                            Grinters.
                            <br /><br />
                            More details will be shared to NFT holders.
                        </p>

                        <br /><br /><br />
                        <div className="pb-5"></div>
                    </div>
                </div>

                <div className="col text-center pt-5">
                    <a href="mint-fergus" className="btn btn-c-2">
                        Mint a Fergus!
                    </a>
                </div>
            </div>
        </section>
    );
}

export default LockerRoom;