function Footer() {
    return (
        <footer>
            <div className="section d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-3 col-sm-2 col-lg-2 col-xl-1"></div>
                        <div className="col-7 col-sm-7 col-lg-5 d-flex justify-content-around align-items-center">
                        </div>
                    </div>
                </div>
            </div>

            <div className="logo pl-3">
                <img src="assets/images/tg-logo.png" className="img-fluid" />
            </div>

            <div className="section d-flex align-items-center py-4 py-sm-0">
                <div className="container">
                    <div className="row">
                        <div className="col-3 col-sm-2 col-lg-2 col-xl-1"></div>
                        <div className="col-9 col-sm-10 col-lg-10 d-flex">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a className="nav-link active" href="/terms-conditions">Terms & Conditions</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/contact-us">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;


