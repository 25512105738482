import Layout from '../Layout/Layout';
function ContactUs() {
    return (
        <Layout>
            <section id="terms-conditions">
                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-sm-10">

                        <div className="row">
                            <div className="col text-center">
                                <label className="subtitle-1 text-center d-block">
                                    Contact Us
                                </label>
                            </div>
                        </div>

                        <div className="container-tr-3 mt-3">
                            <div className='row g-3'>
                                <div className='col-4 col-sm-2'>
                                    <div class="nft-card-2 p-2 bg-red text-center">
                                        <img class="img-fluid" src="assets/images/nfts/fergus-pi-red-red.png" alt="fergus" />
                                    </div>
                                </div>
                                <div className='col-8 col-sm-10'>
                                    <label className='subtitle-4'>Join Our Discord</label>
                                    <p>
                                        Learning about obtaining all the benefits that come with owning a fergus or if you have any additional questions about our NFT's.
                                    </p>

                                    <a href="https://discord.gg/kRYqN2w7HZ" target="_blank" class="btn btn-c-3 d-none d-sm-block">Join our Discord</a>
                                </div>
                                <div className='col-12 d-sm-none text-center'>
                                    <a href="https://discord.gg/kRYqN2w7HZ" target="_blank" class="btn btn-c-3">Join our Discord</a>
                                </div>
                            </div>
                        </div>


                        <div className="container-tr-3 mt-5">
                            <div className='row g-3'>
                                <div className='col-4 col-sm-2'>
                                    <div class="nft-card-2 p-4 bg-white text-center">
                                        <img class="img-fluid" src="assets/images/envelope.png" alt="email" />
                                    </div>
                                </div>
                                <div className='col-8 col-sm-10'>
                                    <label className='subtitle-4'>Contact us by email</label>
                                    <p>
                                        Reach out to us with the email address below if you have any further questions.
                                    </p>
                                    <a href="mailto:ContactUs@TheGrint.com" className='text-decoration-underline text-white'>ContactUs@TheGrint.com</a>
                                </div>

                                <div className='col-12 d-sm-none text-center'>
                                    <br />
                                    <a href="mailto:ContactUs@TheGrint.com" className='text-decoration-underline text-white'>ContactUs@TheGrint.com</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default ContactUs;