import Web3 from 'web3/dist/web3.min.js';

import contractJSON from '../../Assets/Contracts/TheGrint.json';
const mainNetURL = "https://mainnet.infura.io/v3/4b049154ef344752a2fd00bf762cd4a5";
const contractAddress = "0x0C68Ccd3670A8332f50b93D8eB952b6528f7f938";

/*import contractJSON from '../../Assets/Contracts/TheGrint-test.json';
const mainNetURL = "https://rinkeby.infura.io/v3/4b049154ef344752a2fd00bf762cd4a5";
const contractAddress = "0xC6FEaeAbC28C59284E4a2D5b46BC29108c6Ef349";*/

export let accounts;
export let contract;

export const getWeb3 = async () => {
    if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        try {
            await window.ethereum.request({ method: "eth_requestAccounts" });
            window.ethereum.on("accountsChanged", handleAccountsChanged);
            accounts = await web3.eth.getAccounts();
            contract = await getContract(web3);
            return web3;
        } catch (error) {
            throw new Error("We're sorry, but an error occurred when attempting to connect to your wallet.");
        }
    } else {
        throw new Error("Please install MetaMask.");
    }
};

export const checkForConnection = async () => {
    if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        accounts = await web3.eth.getAccounts();
        contract = await getContract(web3);
    }
};

export const handleAccountsChanged = (newAccounts) => {
    accounts = newAccounts;
};

export const getContract = async (web3) => {
    const contract = new web3.eth.Contract(contractJSON, contractAddress);
    return contract;
};

export const getCurrentSupply = async () => {
    if (accounts.length > 0 && contract) {
        const supply = await contract.methods
            .totalSupply()
            .call({ from: accounts[0] });
        return supply;
    }
};

export const getMaxSupply = async () => {
    if (accounts.length > 0 && contract) {
        const supply = await contract.methods
            .maxSupply()
            .call({ from: accounts[0] });
        return supply;
    }
};

export const weiToEth = (value) => {
    return Web3.utils.fromWei(value, "ether");
};

//The following methods do not require a MetaMask wallet to be installed.
//These should be used to fill the bars in the UI and check wallets for ownership

export const getSupplyFromMainNet = async () => {
    const web3 = new Web3(mainNetURL);
    const contract = await getContract(web3);
    const supply = await contract.methods.totalSupply().call()
    return supply;
};

export const getTokensForWallet = async (address) => {
    const web3 = new Web3(mainNetURL);
    const contract = await getContract(web3);
    const nfts = await contract.methods
        .walletOfOwner(address)
        .call({ from: address });
    return nfts;
};
