export function ProgressBar(props) {

    let currentTotalSupply = parseInt(props.currentTotalSupply);
    let bars = [
        { minValue: 0, maxValue: 9, precetange: 0 },
        { minValue: 9, maxValue: 90, precetange: 0 },
        { minValue: 90, maxValue: 900, precetange: 0 }
    ]
    bars.forEach((bar) => {
        if (currentTotalSupply >= bar.minValue && currentTotalSupply <= bar.maxValue) {
            bar.minValue = (currentTotalSupply - bar.minValue);
        } else if (bar.maxValue < currentTotalSupply) {
            bar.minValue = bar.maxValue;
        } else {
            bar.minValue = 0
        }
        bar.precetange = (bar.minValue * 100) / bar.maxValue;
    });

    return (
        <div className="mint-progress">
            <div className="d-flex flex-row bd-highlight mb-3" style={{ width: "1007px" }}>
                <div className="active" style={{ width: "13%", marginRight: "4px" }}>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar"
                            style={{ width: bars[0].precetange + "%", backgroundColor: "#F7D970", borderRadius: "12px 0px 0px 12px" }} aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <label>OG ( {bars[0].minValue} / {bars[0].maxValue} )</label>
                </div>
                <div className="" style={{ width: "31%", marginRight: "4px" }}>
                    <div className="progress" style={{ borderRadius: "0px" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: bars[1].precetange + "%", backgroundColor: "#F7D970" }}></div>
                    </div>
                    <label>FE90 ( {bars[1].minValue} / {bars[1].maxValue} )</label>
                </div>
                <div className="" style={{ width: "56%" }}>
                    <div className="progress" style={{ borderRadius: "0px 12px 12px 0px" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: bars[2].precetange + "%", backgroundColor: "#F7D970" }}></div>
                    </div>
                    <label>SE900 ( {bars[2].minValue} / {bars[2].maxValue} )</label>
                </div>
            </div>
        </div>
    );
}

