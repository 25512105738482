import * as Utils from './Utils';

export const mintFergus = async (amount) => {

  const mainNetwork = "main";
  //const mainNetwork = "rinkeby";
  
  let web3 = null;
  try {
    web3 = await Utils.getWeb3();
  } catch (error) {
    throw new Error(error.message);
  }

  const currentSupply = await Utils.getCurrentSupply();
  const maxSupply = await Utils.getMaxSupply();
  const network = await web3.eth.net.getNetworkType();

  if (Utils.accounts.length <= 0) {
    throw new Error("It looks like you don't have a wallet set up yet. Please set up a wallet in MetaMask to mint a Fergus!");
  }

  if (!Utils.contract) {
    throw new Error("An error occurred attempting to load the smart contract. Please try again.");
  }

  if (network !== mainNetwork) {
    throw new Error("You need to be connected to the main Ethereum network to mint a Fergus. Please check your wallet connection and try again.");
  }

  if (amount + +currentSupply > +maxSupply) {
    throw new Error(`Max supply reached. The current supply is at ${currentSupply} and the max supply is ${maxSupply}`);
  }

  const selectedAccount = Utils.accounts[0];
  const cost = await Utils.contract.methods.cost().call({ from: selectedAccount });
  const accountBalance = await web3.eth.getBalance(selectedAccount);

  const value = +cost * amount;
  const gasLimit = amount * 285000;

  if (+accountBalance > value) {
    try {
      const mintResponse = await Utils.contract.methods.mint(amount).send({
        from: selectedAccount,
        value,
        gas: gasLimit,
      });

      let tokensIds = [];
      let transfer = mintResponse.events.Transfer;
      if(Array.isArray(transfer)){
        transfer.forEach(element => (
          tokensIds.push(element.returnValues.tokenId)
        ));
      }else{
        tokensIds.push(transfer.returnValues.tokenId)
      }

      return {
        tokensIds: tokensIds,        
        status:true
      };
    } catch (error) {    
      if (error.code === 4001) {    
        //user rejected the mint. fail silently.
        return {
          message: '',        
          status:false
        };  
      }        
      return {
        message: error.message,        
        status:false
      };      
    }
  } else {
    throw new Error(`Insufficient funds. You need at least ${Utils.weiToEth(value.toString())} ETH + gas to mint a Fergus.`);
  }
}