function Menu() {
    return (
        <nav className="main-menu navbar navbar-expand-md fixed-top">
            <div className="container">
                <a className="collapsed d-md-none" id="open-menu" href="#navbarCollapse" role="button" aria-controls="navbarCollapse">
                    <img src="assets/images/icon-menu.png" alt="menu" />
                </a>
                <a href="/mint-fergus" className="btn btn-c-1 d-md-none">
                    Mint a Fergus!
                </a>

                <div className="offcanvas offcanvas-start" id="navbarCollapse">
                    <div className="offcanvas-header">
                        <a id="close-menu" href="#" data-bs-dismiss="offcanvas" aria-label="Close">
                            <img className="img-fluid" src="assets/images/close-menu.png" alt="Close" />
                        </a>
                    </div>

                    <ul className="navbar-nav justify-content-between w-75 mx-auto">
                        <li className="nav-item pl-sm-2">
                            <a className="nav-link" aria-current="page" href="/#home">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#collection">The Collection</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#should-i-join">Why Should I Join?</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#how-to-buy">How to Buy and Join?</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#locker-room">The Locker Room</a>
                        </li>
                        <li className="nav-item text-center">
                            <a href="/mint-fergus" className="btn btn-c-1">
                                Mint a Fergus!
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav >
    );
}

export default Menu;


