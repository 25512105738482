function Collection() {
    return (
        <section id="collection">
            <div className="row justify-content-center align-items-center">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4">

                    <label className="text-center subtitle-1 d-sm-none mb-4 mb-sm-0">
                        The Collection
                    </label>

                    <div className="row justify-content-center">
                        <div className="col-10 col-sm-10 col-xxl-10">
                            <div className="nft-card p-3 bg-tg text-center">
                                <img className="img-fluid" src="assets/images/nfts/fergus-p1-green-blue.png" alt="fergus" />
                            </div>
                            <p>
                                <label className="subtitle-3">
                                    Meet Fergus
                                </label>
                                Originally created in 2017 by team members of TheGrint, Fergus was intended to be an
                                instructional avatar for using TheGrint app. He was conceived as a sarcastic representation of what golf
                                used to be perceived as.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div className="container-tr-1">
                        <label className="subtitle-2">
                            What is the Collection?
                        </label>

                        <p>
                            TheGrint's Locker Room is an NFT collection that provides value and benefits to its members by
                            leveraging
                            TheGrint app reach and its community.
                            <br /><br />
                            Holding a Locker Room NFT is like being a member of our private club.
                            <br />
                            <a href="#should-i-join">
                                See our benefits list here.
                            </a>
                            <br /><br />
                            NFT items will be posted in the OpenSea Marketplace.
                        </p>

                        <br />

                        <label className="subtitle-2">
                            Our Goal?
                        </label>

                        <p>
                            The objective is to utilize the funds generated from the sale of the NFT collection to create TheGrint
                            LockerRoom in the Metaverse.
                            <br />
                            <a href="#locker-room">What is the Locker Room? </a>
                        </p>
                        <br /><br />
                        <div className="pb-5"></div>
                    </div>
                </div>

                <div className="col-12 text-center pt-5">
                    <a href="https://opensea.io/collection/thegrint" className="btn btn-c-3" target="_blank">
                        View Opensea Collection
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Collection;