function ShouldJoin() {
    return (
        <section id="should-i-join">
            <div className="row justify-content-center">

                <label className="text-center subtitle-1 d-sm-none mb-4 mb-sm-0">
                    Why Should I Join?
                </label>

                <div className="col-12">

                    <div className="container-tr-2">

                        <div className="row align-items-center">

                            <div className="col-12 col-md-6">

                            <br /><br /><br />

                                <label className="subtitle-2">
                                    Benefits
                                </label>

                                <ul>
                                    <li className="mb-3 mb-sm-0">
                                        TheGrint PRO+ Membership ($99/year value)
                                    </li>
                                    <li className="mb-3 mb-sm-0">
                                        TheGrint Tour Membership ($150/year value)
                                    </li>
                                    <li className="mb-3 mb-sm-0">
                                        Exclusive Events only available to NFT holders.
                                    </li>
                                    <li className="mb-3 mb-sm-0">
                                        Discounts in golf equipment from Gimme.
                                    </li>
                                    <li>
                                        Locker Room Membership (to be developed)
                                    </li>
                                </ul>

                                <p>
                                    As the Locker Room community grows we will have more leverage to add even more benefits for our
                                    members
                                    from our team and also from other golf brands and partners.
                                    <br />
                                    <a href="#terms-conditions" className="m-0 my-sm-4 d-block">
                                        Terms & Conditions
                                    </a>
                                </p>

                                <br /><br /><br />
                            </div>

                            <div className="col-12 col-md-6 text-center mt-5 mt-sm-0">
                                <div className="row justify-content-center">
                                    <div className="col-8 col-sm-11">
                                        <img className="img-fluid" src="assets/images/3-fergus.png" alt="3 Fergus" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="text-center mt-5">
                    <a href="/mint-fergus" className="btn btn-c-2">
                        Mint a Fergus!
                    </a>
                </div>

            </div>
        </section>
    );
}

export default ShouldJoin;