import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import MintaFergus from './Routes/MintaFergus';
import Index from './Routes/Index';
import TermsConditions from './Routes/TermsConditions';
import ContactUs from './Routes/ContactUs';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="mint-fergus" element={<MintaFergus />} />
      <Route path="terms-conditions" element={<TermsConditions />} />
      <Route path="contact-us" element={<ContactUs />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
