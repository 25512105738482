function Home() {
    return (
        <section id="home">
            <div className="row">
                <div className="col section-main-logo text-center">
                    <img className="img-fluid" src="assets/images/tg-logo.png" alt="TheGrint" />
                    <h2 className="pt-3 title-logo">
                        The LockerRoom
                    </h2>
                </div>
            </div>

            <div className="row mt-4 justify-content-center">
                <div className="col-9 col-md-12">
                    <h1 className="main-title pt-3 pb-2">
                        Let's Build TheGrint in the Metaverse!
                    </h1>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col col-md-9 col-lg-8 col-xl-7 col-xxl-10 p-0 text-center">
                    <img className="img-fluid" src="assets/images/banner-fergus.jpg" alt="banner" />
                </div>
            </div>

            <br /> <br /> <br /><br /> <br /> <br />

            <div className="text-center">
                <a href="/mint-fergus" className="btn btn-c-2">
                    Mint a Fergus!
                </a>
            </div>
        </section>
    );
}

export default Home;