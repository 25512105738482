function MisteryFergus(props) {
    let misteryFergus = [];
    for (let i = 0; i < props.totalToMint; i++) {
        misteryFergus.push(
        <img className="img-fluid mistery-fergus pt-2" key={i} src="assets/images/mystery-fergus.png" alt="Mistery Fergus" />
        )
    }
    return (
        misteryFergus
    );
}
export default MisteryFergus;