import React from 'react';
import * as Views from './Views';
import * as Mint from './Mint';
import * as Utils from './Utils';

class Index extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      maxTotalMint: 5,
      totalToMint: 1,
      viewToShow: "",
      statusMintButton: false,
      currentTotalSupply: 0,
      tokensIds: [],//here will be the token Ids of minted fergus
    };
    this.handleMintFergus = this.handleMintFergus.bind(this);
  }


  async componentDidMount() {
    this.setState({ currentTotalSupply: await Utils.getSupplyFromMainNet() });
  }

  handleTotalMint = value => () => {
    this.setState({ totalToMint: this.state.totalToMint + value });
  };


  handleAcceptTerms = () => {
    this.setState({ statusMintButton: !this.state.statusMintButton });
  }

  handleError = (message) => {
    this.setState({ viewToShow: "form", statusMintButton: false });
    window.alert(message);
  }


  async handleMintFergus() {
    try {
      this.setState({ viewToShow: "loading" });
      var element = document.querySelector("#mint-fergus");
      element.scrollIntoView();
      let result = await Mint.mintFergus(this.state.totalToMint);
      if (result.status == true) {
        this.setState({ viewToShow: "success", tokensIds: result.tokensIds });
      } else if (result.message != '') {
        this.setState({ viewToShow: "error" });
      } else {
        this.setState({ viewToShow: "form", statusMintButton: false });
      }
    } catch (error) {
      this.handleError(error.message);
      return;
    }
  }

  render() {
    let viewToShow;
    if (this.state.viewToShow == "loading") {
      viewToShow = <Views.Loading totalToMint={this.state.totalToMint} />;
    } else if (this.state.viewToShow == "success") {
      viewToShow = <Views.Success tokensIds={this.state.tokensIds} />;
    } else if (this.state.viewToShow == "error") {
      viewToShow = <Views.Error />;
    } else {
      viewToShow = <Views.Form
        totalToMint={this.state.totalToMint}
        maxTotalMint={this.state.maxTotalMint}
        currentTotalSupply={this.state.currentTotalSupply}
        statusMintButton={this.state.statusMintButton}
        handleTotalMint={this.handleTotalMint}
        handleAcceptTerms={this.handleAcceptTerms}
        mintFergus={this.handleMintFergus}
      />;
    }

    return (
      <section id="mint-fergus">
        {viewToShow}
      </section >
    )
  }
};


export default Index;