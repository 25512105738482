import Layout from '../Layout/Layout';
import Index from "../Components/Mint/Index";

function MintaFergus() {
    return (
        <Layout>
            <Index />
        </Layout>
    );
}
export default MintaFergus;  