import React from 'react';
import Menu from './Menu';
import Footer from './Footer';


class Layout extends React.Component {
    render() {
        return (
            <>
                <Menu />
                <div className="main-container container justify-content-between mx-auto">
                    {this.props.children}
                </div>
                <br/><br/><br/><br/>
                <Footer />
            </>
        );
    }
}
export default Layout;