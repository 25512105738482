import Layout from '../Layout/Layout';
import Home from '../Pages/Home';
import Collection from '../Pages/Collection';
import ShouldJoin from '../Pages/ShouldJoin';
import HowToBuy from '../Pages/HowToBuy';
import LockerRoom from '../Pages/LockerRoom';

function Index() {
    return (
        <Layout>
            <Home />
            <Collection />
            <ShouldJoin />
            <HowToBuy />
            <LockerRoom />
        </Layout>
    );
}
export default Index;  