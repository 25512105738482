import Layout from '../Layout/Layout';
function TermsConditions() {
    return (
        <Layout>
            <section id="terms-conditions">
                <div className="row justify-content-center align-items-center">
                    <div className="col-12">

                        <div className="row">
                            <div className="col section-main-logo text-center">
                                <img className="img-fluid" src="assets/images/tg-logo.png" alt="TheGrint" style={{ width: "34px" }} />
                                <h5 className="pt-2 title-logo">
                                    The LockerRoom
                                </h5>
                            </div>
                        </div>

                        <div className="container-tr-2 mt-3">
                            <label className="subtitle-1 text-center d-block">
                                Terms & Conditions
                            </label>
                            <div style={{ height: "600px", overflowY: "scroll" }}>
                                <p className="mt-3">
                                    The following Terms of Use (the "Terms") shall apply when Users view, use, or make purchases through Company Services. Please review the Terms carefully. By accessing or using the Services, you signify your agreement to these Terms. If you do not agree to these Terms, you may not access Company Services. These Terms shall include Company's Privacy Policy, which is incorporated by reference in these Terms.
                                    <br /><br />
                                    1. Definitions<br />
                                    "Company" shall refer to the company Grint LLC, a limited liability corporation registered in Florida, in the business of providing a website and mobile application for golfers worldwide, that gathers data from the User by posting scores, and providing interaction in social network environments with other members. And specifically in this web address thegrint.com/lockerroom administering the sale of the “The LockerRoom NFT Collection".
                                    <br /><br />
                                    "Terms" shall collectively refer to these Terms of Service and the linked Privacy Policy at thegrint.com/about_us/terms_and_conditions;
                                    <br /><br />
                                    "Services" shall collectively refer to all services offered by Company including, but not limited to, access to its website and mobile application, online products, downloadable products, sale of NFT's, any and all services, and ability to purchase consumer goods and services; and
                                    <br /><br />
                                    "User" shall refer to any person or entity accessing, using, or making purchases through Company Services.
                                    <br /><br />
                                    2. Changes to Terms of Service and Notices<br />
                                    Company may modify the Terms at any time, under its discretion. Should there be any material changes to the Terms, Users shall be notified with a one (1) month notice prior to the effective date of the new terms. It shall be posted on Services and on the Company mobile application if applicable. At the beginning of each set of Terms, the effective date shall be posted. Users agree the continued access of Services after the effective date, warrants their acceptance to the modifications.
                                    <br /><br />
                                    3. Service Eligibility<br />
                                    In order to use Services, Users must be 18 years old, or older, and have the requisite power and authority to accept these Terms. If one is under 18 years of age, such person is strictly prohibited from using Services.
                                    <br /><br />
                                    People, businesses, or otherwise, may not access Services if they are a known or unknown competitor, or if they have been previously banned from Services. If you are using Services on behalf of a company, entity, or organization, then you represent and warrant you are: an authorized representative of such organization; have the requisite authority to bind the organization to these Terms; and agree to be bound by these Terms on behalf of such organization.
                                    <br /><br />
                                    4. Service Permission<br />
                                    Company grants Users permission to use Services subject to all restrictions set out in these Terms. Use of Services is at User's own risk, including the risk of exposure to offensive, indecent, inaccurate, objectionable, or otherwise inappropriate content.
                                    <br /><br />
                                    5. Service Availability<br />
                                    Company provides Services to Users, and Services may be interrupted, suspended, un-secure, modified, updated, discontinued, or unavailable in particular locations. This may happen without notice from Company, and Company shall not be held liable for any problems regarding Services availability.
                                    <br /><br />
                                    6. Intellectual Property<br />
                                    Company owns all intellectual property connected to Services, including but not limited to its website, applications, or otherwise. This shall include visual interfaces, graphic design, website design, logo, application development, interactive features, compilation, technological code, software, Company hardware, and any method of compiling or analyzing Users' Content. This shall be considered Company intellectual property, which is protected worldwide by copyrights, trademarks, trade secrets, and any other applicable intellectual property rights. These rights shall apply to property now existing or that which is created in the future by Company. User may not copy, reproduce, distribute, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of Company content on Services. Such unauthorized use may violate copyright, trademark, and communication regulations.
                                    <br /><br />
                                    Company shall be able to use any ideas or suggestions made by Users in their Company Services, if Company chooses to do so. User is not entitled to any remuneration of any kind, under any circumstances, for such information. Any submitted suggestion shall provide Company with complete ownership and any intellectual property rights arising therefrom. Company shall be entitled to unrestrictive use of such information.
                                    <br /><br />
                                    USGA Hadicap system, USGA trademark, Course Rating, and Handicap Index are registered trademarks of the United States Golf Association ("USGA").
                                    <br /><br />
                                    7. Privacy Policy<br />
                                    By using Company Services, website, or application, Users hereby agree to the Company's Privacy Policy which may be found on Company website.
                                    <br /><br />
                                    8. Prohibited Conduct<br />
                                    User hereby agrees, while using Services, not to commit any of the following actions:
                                    <br /><br />
                                    Upload content or any information that could damage, disable, overburden, or impair any of Company's servers or networks;
                                    Attempt to gain any unauthorized access to other Users' account, Company computer networks, systems or infrastructure, by any means; attempt to gain access to other Users' accounts, for any reason; use data mining, robots, or other data gathering devices on or through Services; frame or link to the Services without express permission; attempt in any way to reverse engineer, decompile, interrupt, or disassemble, any part of Services or its security features;
                                    Modify, distribute, reproduce, copy, adapt, or create any derivative work in any way, of Service;
                                    In any way restrict other Users or persons from gaining access or creating accounts;
                                    Use any technology or tool that automatically retrieves, alters, or combines Company content or Services;
                                    Reverse engineer any content, Services, website, or application of Company and its Users;
                                    Breach Company rights regarding intellectual property rights, or otherwise; or
                                    Use the Services or content to transmit any computer viruses, Trojan horses, worms, or otherwise, that could potentially cause any damage to software or hardware of Users or Company.
                                    <br /><br />
                                    9. Third Party Sites, Social Media and Embedded Content<br />
                                    Services may contain links, and embedded content from, third party websites, as well as services not operated by Company. Company does not control these services. Linked sites may be provided as a service to Users, and does not imply any endorsement of the activities or content of these sites, nor any association with its operators. Users assume any and all risks, known or unknown, now existing or existing in the future, by following a link to a linked site.
                                    <br /><br />
                                    Examples of sites that may be linked to Company's Services include, but are not limited to: ability to use and share photos, video, and content posted in Company website and mobile application; ability to use and share photos, video, and content in social media channels including but not limited to Facebook, Instagram, YouTube, and Twitter; embedded and ability to link YouTube videos from youtube.com; ability to use and share photos, videos and content taken in golf tournaments promoted by Company ("The Grint Tour").
                                    <br /><br />
                                    The Grint Tour is a tour where participants who are Company members play in a tournament organized by Company. Company will have photographers present, and Users hereby consent to the use of photos taken on The Grint Tour in order for Company to promote its brand in social media channels.
                                    <br /><br />
                                    10. Your NFT Benefits<br />
                                    NFT Holders will enjoy the benefits listed in this website. Grint LLC reserve the right to modify or eliminate your benefits at any moment without previous notice.
                                    <br /><br />
                                    11. Limited Liability and Disclaimers<br />
                                    The following information applies to the maximum extent permitted under law. User rights are not limited beyond those they are rightfully entitled to. By using Company Services, Users hereby agree to the following Terms, and Users forfeiting certain legal rights they may otherwise be entitled to you. If a User is uncertain of any of the following, it is suggested they consult a legal professional.
                                    <br /><br />
                                    Availability. Company provides Services on an "as is" and "as available" basis for Users' use. Company does not guarantee Services will be available in any location for any User, or that Services will always be available at any given time. Company does not warrant Services will be uninterrupted, timely, error-free, or secure. Furthermore, it does not warrant any defects will be corrected. Company does not guarantee its website or applications will be free from viruses or other harmful components.
                                    Security. Company makes every effort to keep its Services, Content, website, and application secure. Company accepts no liability for any loss, disruption, or damage incurred by Users to its data, computer, or software, that is a result of unauthorized access by any person who has caused a loss due to any data breach or hacking, even if such breach or hack was the mistake of Company. Users are responsible for taking their own precautions to ensure the electronic process employed for accessing Services, website, or application, does not expose them to the risk of viruses, malicious computer code, Trojan horses, worms, or otherwise, that may interfere, infect, or damage their computers, software, or data. Furthermore, Company limited liability shall extend should any Company computer, server, or software malfunction, and cause any Users any of the aforementioned losses. Company has taken precautions to prevent any malfunction, however User hereby agrees to accept full liability for any loss, of any kind, should they occur.
                                    Software Updates. Company may make software updates to it mobile application at any given time. In order to use Services, Users may be obligated to download the updated version of the applications. Company may choose to make downloading the updated version of the application a required precedent to continued use of Services. However, Company makes no guarantees any subsequent version of an application will work on Users mobile phone or devices. Company is expressly not liable for any loss incurred due to Users inability to use Services due to the inability to use an updated version of the mobile application on their specific mobile device.
                                    Third Party Transactions. Company does not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the Company Service, hyperlink, website, or any of its applications. Users hereby agree to hold third parties solely liable for any and all claims regarding such transactions with third parties. Any products or services purchased or otherwise acquired by a third party, are separate legal transactions solely between Users and the relevant third party, and in no way involve Company.
                                    Limited Liability. Users assume any and all risk associated with using Company's website, application, or Services. Company, its parents, subsidiaries, affiliates, owners, directors, officers, employees, licensors, distributors, subcontractors, sub-licensees, independent contractors, and agents (collectively known as the "Company Parties") shall not be liable for any direct, indirect, incidental, consequential, special, punitive, or exemplary damages, or losses of any kind. These include without limitation, loss of profits, business, goodwill, use, data, or other intangible losses, whether based in contract, tort, negligence, strict liability, or otherwise, which may be incurred in connection with the use, or inability to use, Services, including interruption, inaccuracy, error, or omission.
                                    Disclaimer. Company expressly disclaims all warranties, express or implied, including but not limited to: implied warranties of merchantability; fitness for a particular purpose; non-infringement; warranties of title and accuracy; warranties regarding products and services offered by all businesses listed on Company's website; and warranties in the course of dealing, performance or usage of trade.
                                    Company's maximum aggregate amount of liability for any loss or damage a User experiences in Company's content, Services, website, application, or otherwise, shall not exceed $100.
                                    <br /><br />
                                    12. Independent Relationship<br />
                                    Users fully understand and accept in no way do these Terms, Services, or otherwise, create a joint venture, agency, partnership, or employment relationship with Company. Company is not liable for any financial or other loss of Users in any way, on any transaction, under any circumstances. Users accept use of Company's Services often involves financial exchange, and the Company shall bear no liability or responsibility therefor.
                                    <br /><br />
                                    13. Class Action Waiver and Other Restrictions.<br />
                                    Arbitration shall proceed solely on an individual basis without the right for any claims to be arbitrated on a class action basis or on bases involving claims brought in a purported representative capacity on behalf of others. The arbitrator's authority to resolve and make written awards is limited to claims between Users and Company alone. Claims may not be joined or consolidated unless agreed to in writing by all parties. No arbitration award or decision will have any preclusive effect as to issues or claims in any dispute with anyone who is not a named party to the arbitration. Notwithstanding any other provision in these Terms (including the "Continuation" provision below), and without waiving either party's right of appeal, if any portion of this "Class Action Waiver and Other Restrictions" provision is deemed invalid or unenforceable, then the entire Arbitration Provision (other than this sentence) shall sever and continue.
                                    <br /><br />
                                    14. Indemnification<br />
                                    Users agree to indemnify, defend, and hold harmless, Company and Company Parties, from and against all: claims; injuries; damages; obligations; losses; lawsuits; attorney fees; legal fees or filing fees from any and all claims or demands made by any party related to Users' use of Service, website, or applications; violations of these Terms, goods and services purchased; or misuse of User accounts. Company reserves the right to execute the exclusive defense of any issues arising out of such indemnification. Users are expressly prohibited from settling such claims without the prior written Consent of Company.
                                    <br /><br />
                                    15. Exclusivity<br />
                                    These Terms are the complete and exclusive statement and the mutual understanding of Users and Company. These Terms supersede and cancel all previous written and oral agreements and communications relating to the subject matter of these Terms. Any waivers or amendments shall be effective only if made in writing and signed by an agent of Company authorized to do so.
                                    <br /><br />
                                    16. Arbitration Agreement<br />
                                    All claims and disputes arising under or relating to these Terms and Services are to be settled by binding arbitration in the state of Florida or another location mutually agreeable to the parties. The arbitration shall be conducted on a confidential basis pursuant to the Commercial Arbitration Rules of the American Arbitration Association. Any decision or award as a result of any such arbitration proceeding shall be in writing and shall provide an explanation for all conclusions of law and fact and shall include the assessment of costs, expenses, and reasonable attorneys' fees. Any such arbitration shall be conducted by an arbitrator experienced in commercial arbitration and shall include a written record of the arbitration hearing. The parties reserve the right to object to any individual who shall be employed by or affiliated with a competing organization or entity. An award of arbitration may be confirmed in a court of diction.
                                    <br /><br />
                                    17. Jurisdiction<br />
                                    These Terms of Use, and any dispute legal or otherwise arising from its implementation, shall be deemed to have been made in, and shall be construed pursuant to the laws of the state of Florida and the United States of America. If any party brings a claim, parties hereby submit and consent to the personal and exclusive jurisdiction of the state of Florida.
                                    <br /><br />
                                    18. Severability<br />
                                    Company's failure to exercise, delay in exercising, any privilege, any power or any rights hereunder will not operate as a waiver thereof, nor will any single or partial exercise of any right or power hereunder preclude further exercise of any other right hereunder. If any provision of these Terms shall be adjudged by any court of competent jurisdiction to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms shall otherwise remain in full force and effect and enforceable.
                                    <br /><br />
                                    19. Termination and Survival<br />
                                    If a User breaches Terms, or any part of the Terms, in any way, then Company may take action up to, and including, suspending Users' access to Services or prohibiting them from accessing Company website or application. Company may take these actions without liability or notice to you. If a User's account is terminated due to a breach in the Terms, Users will not be entitled to any refund of any kind. Furthermore, Users can be barred from any future use of Services.
                                    <br /><br />
                                    The following Sections of these Terms shall survive termination: Communication and Data Retention; Company Intellectual Property; Privacy Policy; Prohibited Conduct; Third Party Sites; Independent Relationship; Arbitration Agreement; Limited Liability and Disclaimers; Class Action Waiver and Other Restrictions; Indemnification; Exclusivity; Severability; Jurisdiction; Termination and Survival.
                                    <br /><br />
                                    20. Notice to California Users<br />
                                    Under California Civil Code Section 1789.3, users of Company Services are entitled to the following contact information: The Complaint Assistance Unit of the Division of Consumer Services at the California Department of Consumer Affairs may be contacted in writing or by telephone at:
                                    <br /><br />
                                    400 R Street, Suite 1080<br />
                                    Sacramento, California<br />
                                    95814<br />
                                    Telephone: (916) 445-1254, or (800) 952-5210
                                    <br /><br />
                                    21. Miscellaneous<br />
                                    Users are entirely responsible for the safety and management of their own private Ethereum wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as our TheGrint Smart Contract runs on the Ethereum network, there is no ability to undo, reverse or restore any transaction. This website and its connected services are provided “as is" and “as available" without warranty of any kind. Purchasing a Fergus NFT from the TheGrint smart contract does not include any guarantee or guarantee of service. The published roadmap is not a promise or guarantee, but our best guess of how the project will advance and sell. Metadata is subject to change until frozen. By using this website you are accepting sole responsibility for any and all transactions involving TheGrint digital collectibles.

                                    <br /><br />
                                    22. Contact Information<br />
                                    2030 S Douglas Rd. Suite 209. Coral Gables, FL. 33134<br />
                                    info@thegrint.com

                                    TheGrint Team
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default TermsConditions;